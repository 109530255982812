export const Welcome = '';
export const Dashboard = 'dashboard';
export const Survey = 'survey';
export const AboutUs = 'about-us';
export const Error = 'error';
export const Password = 'password';
export const JoinUs = 'join-us';
export const MailingList = 'mailing-list';
export const AboutInitiative = 'about_initiative';
export const DataAccess = 'data_access';
export const Statistics = 'statistics';
export const LoginLanding = 'login-landing';
export const Auth = 'auth';
export const PasswordResetDone = 'password-reset-done';
export const SessionExpired = 'session-expired';
export const AccountActivated = 'account-activated';
export const AccountActivationRequired = 'account-activation-required';
export const ParticipantList = 'participants';
export const ActivityId = 'activity/:instanceGuid';
export const LoginLandingMode = 'login-landing/:mode';
export const ActivityPrint = 'activity/:instanceGuid/print';
