export enum ActivityCodes {
  PREQUALIFIER = 'PREQUAL',
  REGISTRATION = 'REGISTRATION',
  CONSENT = 'CONSENT',
  CONSENT_EDIT = 'CONSENT_EDIT',
  ASSENT = 'ASSENT',
  CONTACTING_PHYSICIAN = 'CONTACTING_PHYSICIAN',
  MEDICAL_HISTORY = 'MEDICAL_HISTORY',
  GENOME_STUDY = 'GENOME_STUDY',
  FEEDING = 'FEEDING',
  REVIEW_AND_SUBMISSION = 'REVIEW_AND_SUBMISSION',
  BLOOD_TYPE = 'BLOOD_TYPE',
}
