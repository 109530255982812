<div class="workflow-progress">
  <ul *ngIf="steps.length">
    <ng-container *ngFor="let step of steps; index as i;">
      <li
        [ngClass]="{
            'current': step === currentActivity,
            'in-progress': step.instanceGuid === instanceGuid,
            'in-complete': step.statusCode === COMPLETE || (step.statusCode === IN_PROGRESS && step.instanceGuid !== instanceGuid),
            'blue-hover': step.statusCode === IN_PROGRESS || step.instanceGuid === instanceGuid || step.statusCode === COMPLETE
            }"
        (click)="changeActivity(step)"
      >
        <span class="number">{{ i + 1 }}</span>
        <span class="glyphicon glyphicon-ok"></span>
        <span class="name">
          {{ step.activityName }}
          <span>{{ statuses[ step.statusCode] }}</span>
        </span>
      </li>
    </ng-container>
  </ul>
</div>
