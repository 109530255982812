<section class="about-us">
  <h1 translate>AboutInitiative.Title</h1>
  <div>
    <p translate>
      <b translate>AboutInitiative.FirstParagraph.Title</b> AboutInitiative.FirstParagraph.Text
    </p>
    <hr>
  </div>
  <div>
    <h2 translate>AboutInitiative.SecondParagraph.Title</h2>
    <p translate>
      AboutInitiative.SecondParagraph.TextOne
    </p>
    <p translate>
      AboutInitiative.SecondParagraph.TextSecond
    </p>
    <p translate>
      AboutInitiative.SecondParagraph.TextThird
    </p>
    <hr>
  </div>
  <div>
    <h2 translate>AboutInitiative.ThirdParagraph.Title</h2>
    <p translate>
      AboutInitiative.ThirdParagraph.Text
    </p>
    <p translate>AboutInitiative.ThirdParagraph.SubTitle</p>
    <ul>

      <li *ngFor="let isLast = last; let step of ('AboutInitiative.ThirdParagraph.Steps' | extractTranslationPathsForArray | async)">
        <h2 translate>{{ step }}.Name</h2>
        <p translate>{{ step }}.Text</p>
        <ul class="List" >
          <li *ngFor="let step_level_2 of (step + '.Steps' | extractTranslationPathsForArray | async)" translate>
            {{ step_level_2 }}
          </li>
        </ul>
        <br *ngIf="!isLast">
      </li>
    </ul>
  </div>
</section>
