<section class="statistics page-padding">
  <h1 translate>Statistics.Title</h1>

  <ng-container *ngIf="!isLoading; else loading">
    <ul class="statistics-list">
      <li
        *ngFor="let statistic of statisticListItems"
        class="statistics-list__item"
      >
        <div class="statistic">
          <span class="statistic__count">
            {{ formattedStatistics[statistic] }}
          </span>
          <p class="statistic__description">
            {{ ('Statistics.CountList.' + statistic) | translate }}
          </p>
        </div>
      </li>
    </ul>

    <ng-container *ngIf="formattedStatistics.countriesDistribution.length">
      <h2 class="subtitle subtitle--light">
        {{ 'Statistics.Subtitle' | translate }}
      </h2>

      <ul class="countries-distribution">
        <li
          *ngFor="let dist of formattedStatistics.countriesDistribution"
          class="countries-distribution__item"
        >
          <div class="distribution">
            <div
              class="distribution__bar"
              [style.width.px]="dist.barWidth"
            ></div>

            <span class="distribution__emoji"> {{ dist.emoji }} </span>

            {{ dist.countryName }}
          </div>
        </li>
      </ul>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <mat-spinner></mat-spinner>
  </ng-template>
</section>
