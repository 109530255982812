<div class="self-dashboard">
  <h1 class="title title--grey" translate>Toolkit.Dashboard.Title</h1>
  <h2 class="subtitle subtitle--light" translate>Toolkit.Dashboard.Text</h2>
  <h2 class="title title--green" translate>SDK.Dashboard.Title</h2>

  <div
    *ngIf="isLoading; else userActivities"
    class="self-dashboard__spinner-container"
  >
    <mat-spinner></mat-spinner>
  </div>

  <ng-template #userActivities>
    <div *ngIf="status" class="registration-status">
      <span class="status">
        {{ 'EnrollmentStatus.Prefix' | translate }}
      </span>

      <ng-container *ngIf="isContactUsStatus; else simpleStatus">
        <app-tooltip-button
          style="margin-left: 4px; font-style: italic"
          [message]="'EnrollmentStatus.ContactUsTooltip' | translate"
        >
          {{ enrollmentMessageKey | translate }}
        </app-tooltip-button>
      </ng-container>

      <ng-template #simpleStatus>
        <span class="status">{{ enrollmentMessageKey | translate }}</span>
      </ng-template>
    </div>

    <app-user-activities
      [activities]="activities"
      [isParticipantIneligible]="isContactUsStatus"
      [isUiBlocked]="isUiBlocked"
      (startActivity)="goToActivity($event)"
      (continueActivity)="goToActivity($event)"
      (viewActivity)="goToActivity($event)"
      (editActivity)="onEditActivity($event)"
    ></app-user-activities>
  </ng-template>
</div>
