<section class="about-us">
  <h1 translate>AboutPage.PageTitle</h1>
  <div>
    <p>
      <b translate>AboutPage.PlatformName</b>
      <span translate>AboutPage.Text.First</span>
    </p>
    <p translate>AboutPage.Text.Second</p>
    <p translate>AboutPage.Text.Third</p>
    <hr>
  </div>
  <div>
    <h2 translate>AboutPage.FamilyOversight.Header</h2>
    <p translate>AboutPage.FamilyOversight.Text</p>
    <ul>
      <li *ngFor="let hospital of ('AboutPage.Hospitals'| extractTranslationPathsForArray | async)">
        <span translate>{{ hospital }}.Name</span> (<i translate>{{hospital}}.Country</i>)</li>
    </ul>
    <hr>
  </div>
  <div>
    <h2 translate>AboutPage.ScientificBoard.Header</h2>
    <p translate>AboutPage.ScientificBoard.Text</p>
    <ul>
      <li *ngFor="let doctor of ('AboutPage.Doctors' | extractTranslationPathsForArray | async)">
        <span translate >{{ doctor }}.Name</span>,
        <span translate >{{ doctor }}.Rank</span> -
        <i translate >{{ doctor }}.Hospital</i>
      </li>
    </ul>
    <hr>
  </div>
  <div>
    <h2 translate>AboutPage.DataAccess.Header</h2>
    <p translate>AboutPage.DataAccess.Text</p>
    <hr>
  </div>
  <div>
    <h2 translate>AboutPage.FinancialSupport.Header</h2>
    <p translate>AboutPage.FinancialSupport.Text.First</p>
    <p translate>AboutPage.FinancialSupport.Text.Second</p>
  </div>
</section>
