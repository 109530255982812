<footer class="footer">
  <div>
    <div>
      <i class="fa fa-fw fa-envelope-square"></i>
      <a class="footer-link" href="mailto:support@atfamilies.org" translate
        >Footer.Contacts.Email</a
      >
    </div>
    <div class="contacts__entry" translate>
      <i class="fa fa-fw fa-phone-square"></i>
      Footer.Contacts.Phone
    </div>
  </div>
  <a [routerLink]="[RouterResource.Welcome]">
    <img src="assets/images/atcp/original-logo-color.svg" />
  </a>
</footer>
